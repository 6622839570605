/**
 * Palette
 * -------
 * #181818
 * #455860
 * #5e6f76
 * #f1f1f1
 * #ffffff
 */

.NavDrawer {
  height: 100%;
  width: 200px;
  background-color: #455860;
  color: #f1f1f1;
  display: inline-block;
  float: left;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-weight: 300;
  margin-right:-130px;
  z-index: 100;
}

.NavDrawer:hover ~ .MainContainer {
  transform: translateX(130px);
  -webkit-transform: translateX(130px);
}

.NavDrawer ul {
  height: 100%;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.NavDrawer ul li {
  height: 70px;
  width: 200px;
  cursor: pointer;
}

.NavDrawer ul li:hover {
  background-color: #f1f1f1;
  color: #455860;
}

.NavButton svg {
  height: 20px;
  width: 20px;
  display: inline-block;
  fill: #f1f1f1;
  font-size: 20px;
  line-height: 20px;
  margin: 25px;
  text-align: center;
  vertical-align: middle;
}

.NavButton:hover svg {
  fill: #455860;
}

.NavButton .label {
  height: 20px;
  width: 180px;
  line-height: 20px;
}
