/**
 * Palette
 * -------
 * #181818
 * #455860
 * #5e6f76
 * #f1f1f1
 * #ffffff
 */

.NavLinks {
  height: 20px;
  opacity: 0;
  padding: 10px;
  text-align: center;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.NavDrawer:hover .NavLinks {
  opacity: 1;
}

.NavLinks .icon {
  height: 20px;
  padding: 0.1em;
  width: 20px;
  fill: #f1f1f1;
  font-size: 20px;
  transition: opacity 300ms;
  -webkit-transition: opacity 300ms;
}

.NavLinks .icon:hover {
  opacity: 0.5;
}
