/**
 * Palette
 * -------
 * #181818
 * #455860
 * #5e6f76
 * #f1f1f1
 * #ffffff
 */

.Article {
  height: 350px;
  width: 250px;
  background-color: #ffffff;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin: 20px 10px 0;
  transition: box-shadow 300ms, transform 300ms;
  -moz-transition: box-shadow 300ms, transform 300ms;
  -o-transition: box-shadow 300ms, transform 300ms;
  -webkit-transition: -webkit-box-shadow 300ms, -webkit-transform 300ms;
}

.Article:hover {
  box-shadow: 0 3px 7px #5e6f76;
  -moz-box-shadow: 0 3px 7px #5e6f76;
  -webkit-box-shadow: 0 3px 7px #5e6f76;
  transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}

.Article img {
  height: 150px;
  width: 100%;
}

.Article .contentContainer {
  padding: 5px;
}

.Article .title {
  color: #455860;
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 300;
  padding: 5px;
}

.Article .date {
  color: #455860;
  padding: 5px;
}

.Article .content {
  color: #455860;
  padding: 5px;
}
